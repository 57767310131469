<template>
  <div class="news-list-container">
    <h1>资讯列表</h1>
    <ul class="news-list">
      <li v-for="news in newsList" :key="news.id">
        <a :href="news.link" target="_blank">
          <h2>{{ news.title }}</h2>
          <p>{{ news.description }}</p>
          <p class="news-date">{{ news.date }}</p>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newsList: [
        {
          id: 1,
          title: "科技前沿：人工智能新突破",
          description: "详细介绍了人工智能领域近期的一项重大突破，及其对各行业可能产生的影响。",
          date: "2024-11-01",
          link: "https://example.com/ai-breakthrough"
        },
        {
          id: 2,
          title: "娱乐热点：某明星新剧开机",
          description: "报道了某知名明星新剧开机的消息，包括主演阵容和剧情简介等内容。",
          date: "2024-11-02",
          link: "https://example.com/star-new-drama"
        },
        {
          id: 3,
          title: "体育赛事：世界杯预选赛精彩瞬间",
          description: "回顾了世界杯预选赛中的一些精彩比赛瞬间，以及各支球队的表现。",
          date: "2024-11-03",
          link: "https://example.com/world-cup-qualifiers"
        }
      ]
    };
  }
};
</script>

<style scoped>
.news-list-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #333;
}

.news-list {
  list-style-type: none;
  padding: 0;
}

.news-list li {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.news-list li a {
  text-decoration: none;
  color: #007bff;
  transition: color 0.3s ease;
}

.news-list li a:hover {
  color: #0056b3;
}

h2 {
  color: #555;
  margin: 0;
}

p {
  color: #666;
  margin: 5px 0;
}

.news-date {
  font-size: 14px;
  color: #888;
}
</style>